import React, { useEffect } from 'react';
import { navigate } from 'gatsby';

import Layout from '../../components/Layout/Layout';
import ModalSuccessBuyPack from '../../components/UI/ModalBuyPack/ModalBuyPack';

import classes from './index.module.scss';

const Index = ({ location }) => {

    const { search, pathname } = location

    const successMessage = `Pack successfully purchased, please check your e-mail.`;

    useEffect(() => {
        if (search?.length) {
            navigate(pathname);
        }
    }, [])

    return(
        <Layout mainClasses={`${classes.heightFull}`}>
            <div className={`blue-grad-bg center-vertical-page-wrapper center-content-vertical-mobile ${classes.heightFull}`}>
                <div className={`${classes.container} container-bordered auth-wrapper`}>
                    <div>
                        <ModalSuccessBuyPack
                            successMessage={successMessage}
                        />
                    </div>
                </div>
            </div>
        </Layout>
    )
};

export default Index;