import React, { useCallback } from "react";
import { navigate } from 'gatsby';

import Button from '../Button/Button';

import styles from "./ModalBuyPack.module.scss";

const ModalSuccessBuyPack = ({
    successMessage = ''
}) => {
    const handleSubmit = useCallback(() => {
        navigate('/');
    }, [navigate])

    return(
        <div>
            <div className={`${styles.subTitle}`}>
                <p className={`${styles.location}`}>{successMessage}</p>
            </div>
            <p className={`${styles.title}`}></p>
            <Button
                classes={`t-600 btn btn-uppercased btn_common ${styles.button}`}
                type="submit"  
                onClick={handleSubmit}
            >
                Return to homepage
            </Button>
        </div>
    )
}

export default ModalSuccessBuyPack;